import $ from "jquery";
import {activeParentCodes, sessionPrefix} from "./index";
import {
    restrictionsAccordingToBaseLength,
    restrictionsAccordingToBaseWidth,
    restrictionsAccordingToHeadBoardWidth,
    setMinMaxValueAccordingToBase,
    setMinMaxValueAccordingToHeadboard,
    updateSlider,
} from "./dimensionsFunctions";
import {findSelections} from "./pureSelect";
import html2canvas from 'html2canvas';

import {
    BASES_MENU_NAME,
    BED_GENERAL_PRICE_CHECKBOX,
    BEDS,
    DIAKOSMITIKA_MAKSILARIA_CONST_NAME,
    HEADBOARD_MENU_NAME,
    KATOSENTONO_CONST_NAME,
    KOUVERLI_CONST_NAME,
    LEFKA_EIDH_GENERAL_PRICE_CHECKBOX,
    LEYKA,
    MAKSILARIA_CONST_NAME,
    PANOSENTONO_CONST_NAME,
    PAPLOMATOTHIKI_CONST_NAME,
    SET_SENTONIA_CONST_NAME,
} from "./consts";
import aiPic from "../../public/images/ai-pic.png";
import OpenAI from "openai";

import {getRequest, postRequest} from "./requests";
import Swal from "sweetalert2";
import {sendStatisticToServer} from "./helpers";
import {showHideDivs} from "./showHideDivs";

let [connectRange] = $("#connect-range");

const BASE_WIDTH_RANGE_SELECTOR = "#base-width-range",
    BASE_LENGTH_RANGE_SELECTOR = "#base-length-range",
    HEADBOARD_WIDTH_RANGE_SELECTOR = "#headboard-width-range";

// window.addEventListener("resize", () => {
//     let width = window.innerWidth;
//     if (width > 1200) {
//         $("#starting-price-container-bed").hasClass("hidden")
//             ? $("#user-buttons-container").css("width", "374px")
//             : $("#user-buttons-container").css("width", "556px");
//         $("#starting-price-container-bed").hasClass("hidden")
//             ? $("#selections-container").css("width", "358px")
//             : $("#selections-container").css("width", "489px");

//         $("#starting-price-container-leyka").hasClass("hidden")
//             ? $("#user-buttons-container").css("width", "374px")
//             : $("#user-buttons-container").css("width", "556px");
//         $("#starting-price-container-leyka").hasClass("hidden")
//             ? $("#selections-container").css("width", "358px")
//             : $("#selections-container").css("width", "489px");
//     } else {
//         $("#user-buttons-container").css("width", "100%");
//         $("#selections-container").css("width", "100%");
//     }
// });

function createObjectForPostRequest(modalId) {
    const namesToProps = {
        "Fabric Meters": "fabricMeters",
        "Fabric Pillow Meters": "fabricPillowMeters",
        "Num of Bedfeet": "numOfBedfeet",
    };

    // build the object from the name and inputs of the modal body
    const data = {
        showInFrontend: {},
        dontShowInFrontend: {},
    };
    //    get the modal non-editable-area
    const showInFrontend = $(`${modalId} .modal-body .showInFrontend`),
        dimensionsPricesNodeList = $(
            `${modalId} .modal-body .dimensionsPrices`
        ),
        fasaNodeList = $(`${modalId} .modal-body .fasa`),
        dimensionsBedfeetNodeList = $(
            `${modalId} .modal-body .dimensionsBedfeet`
        );
    // showInfrontendArray and dontShowInfrontendArray
    const showInFrontendArray = [
            "name",
            "max-width",
            "min-width",
            "width-step",
            "max-length",
            "min-length",
            "length-step",
            "price",
            "code",
            "color",
            "category",
            "subCategory",
        ],
        dontShowInFrontendArray = [
            "width",
            "price",
            "Fabric Meters",
            "Num of Bedfeet",
            "Fabric Pillow Meters",
        ];

    showInFrontend.each(function (index, element) {
        let elementsTextContent = element.textContent.trim();
        let elementsFirstToLowerCase =
            elementsTextContent[0].toLowerCase() +
            elementsTextContent.substring(1);
        let value = $(this)
            .parent()
            .parent()
            .children(".col-7")
            .children()
            .val();
        if (
            showInFrontendArray.includes(elementsFirstToLowerCase) &&
            elementsFirstToLowerCase !== "price"
        ) {
            data.showInFrontend[elementsFirstToLowerCase] = value;
        } else if (
            showInFrontendArray.includes(elementsFirstToLowerCase) &&
            elementsFirstToLowerCase === "price"
        ) {
            data.showInFrontend[elementsFirstToLowerCase] = $(
                "#input-color-price"
            ).prop("disabled")
                ? null
                : Number(value);
        }
        // data.showInFrontend
    });

    if (dimensionsPricesNodeList.length > 0) {
        data.dontShowInFrontend["dimensionsPrices"] = [];
        const {dimensionsPrices} = data.dontShowInFrontend;

        // loop through all the elements in the nodelist for dimensionsPrices
        dimensionsPricesNodeList.each(function (index, element) {
            let elementsTextContent = element.textContent.trim();
            let elementTextContentToLowerCase =
                elementsTextContent[0].toLowerCase() +
                elementsTextContent.substring(1);
            let value = $(this)
                .parent()
                .parent()
                .children(".col-7")
                .children()
                .val();
            let arraysIndex = Number($(this).attr("data-index"));

            if (!dimensionsPrices[arraysIndex]) {
                dimensionsPrices[arraysIndex] = {};
            }

            if (
                dontShowInFrontendArray.includes(elementsTextContent) &&
                elementsTextContent in namesToProps
            ) {
                dimensionsPrices[arraysIndex][
                    namesToProps[elementsTextContent]
                    ] = parseFloat(value);
            } else if (
                dontShowInFrontendArray.includes(elementTextContentToLowerCase)
            ) {
                dimensionsPrices[arraysIndex][elementTextContentToLowerCase] =
                    parseFloat(value);
            }
        });
    }

    if (fasaNodeList.length > 0) {
        data.dontShowInFrontend["fasa"] = {};
        const {fasa} = data.dontShowInFrontend;

        // loop through all the elements in the nodelist for fasa
        fasaNodeList.each(function (index, element) {
            let elementsTextContent = element.textContent.trim();
            let elementTextContentToLowerCase =
                elementsTextContent[0].toLowerCase() +
                elementsTextContent.substring(1);
            let value = $(this)
                .parent()
                .parent()
                .children(".col-7")
                .children()
                .val();
            if (
                dontShowInFrontendArray.includes(elementsTextContent) &&
                elementsTextContent in namesToProps
            ) {
                fasa[namesToProps[elementsTextContent]] = parseFloat(value);
            } else if (
                dontShowInFrontendArray.includes(elementTextContentToLowerCase)
            ) {
                fasa[elementTextContentToLowerCase] = parseFloat(value);
            }
        });
    }

    if (dimensionsBedfeetNodeList.length > 0) {
        data.dontShowInFrontend["dimensionsBedfeet"] = [];
        const {dimensionsBedfeet} = data.dontShowInFrontend;

        // loop through all the elements in the nodelist for dimensionsPrices
        dimensionsBedfeetNodeList.each(function (index, element) {
            let elementsTextContent = element.textContent.trim();
            let elementTextContentToLowerCase =
                elementsTextContent[0].toLowerCase() +
                elementsTextContent.substring(1);
            let value = $(this)
                .parent()
                .parent()
                .children(".col-7")
                .children()
                .val();
            let arraysIndex = Number($(this).attr("data-index"));
            if (!dimensionsBedfeet[arraysIndex]) {
                dimensionsBedfeet[arraysIndex] = {};
            }
            if (
                dontShowInFrontendArray.includes(elementsTextContent) &&
                elementsTextContent in namesToProps
            ) {
                dimensionsBedfeet[arraysIndex][
                    namesToProps[elementsTextContent]
                    ] = parseFloat(value);
            } else if (
                dontShowInFrontendArray.includes(elementTextContentToLowerCase)
            ) {
                dimensionsBedfeet[arraysIndex][elementTextContentToLowerCase] =
                    parseFloat(value);
            }
        });
    }

    const {id, name} = $(
        "#context-menu-content #context-menu-edit-item"
    ).data().set;
    if (name !== data.showInFrontend.name) {
        $(`[data-id="${id}"] span`).text(data.showInFrontend.name);
    }
    return data;
}

async function createModalContent(options) {
    const saveButton = $(
        "#edit-modal > div > div > div.modal-footer > button.btn.btn-primary"
    );
    const propsToNames = {
        fabricMeters: "Fabric Meters",
        fabricPillowMeters: "Fabric Pillow Meters",
        numOfBedfeet: "Num of Bedfeet",
    };

    if (saveButton.attr("disabled")) saveButton.removeAttr("disabled");

    const {realCategory, id, name} = options;

    $("#edit-modal-title")
        .html("")
        .append("Επεξεργασία του " + name);
    const content = `
    <div id="non-editable-content" class="container mb-4">
        
    </div>
    <div id="editable-content" class=" container">
    
    </div>
    `;
    const URLopts = {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": $("input[name=csrfmiddlewaretoken]").val(),
        },
    };
    const Url = `/gpoi/?id=${id}&category=${realCategory}`;

    let res = await fetch(Url, URLopts);
    const {properties, priceOfParent} = await res.json();

    $("#edit-modal > div > div > div.modal-body").html(content);

    switch (realCategory) {
        case "a": {
            $("#editable-content").remove();
            let nonEditableContent = $("#non-editable-content");
            for (let [key, value] of Object.entries(options)) {
                nonEditableContent[0].innerHTML += `
                <div class="card">
                    <div class="card-body">
                        <div class="row w-100">
                           <div class="col-5">
                            <p class="non-editable-item-name">${
                    key[0].toUpperCase() + key.substring(1)
                }</p>
                            </div>
                            <div class="col-7 text-end">
                            <p class="non-editable-value">${value}</p>
                            </div>
                        </div>
                    </div>
                </div>
                `;
                saveButton.attr("disabled", "true");
            }
            break;
        }
        default: {
            let nonEditableContent = $("#non-editable-content"),
                editableContent = $("#editable-content");
            const priceCheckbox = `<div class="form-check" style="margin-right:30px;margin-left: auto;width: fit-content;">
              <input class="form-check-input" type="checkbox" id="unlock-price">
              <label class="form-check-label" for="unlock-price">
                Άλλαξε την τιμή
              </label>
                            </div>`;
            const returnToparentPrice = `<div class="form-check" style="margin-right:30px;margin-left: auto;width: fit-content;">
              <input class="form-check-input" type="checkbox" id="parent-price">
              <label class="form-check-label" for="parent-price">
                Επαναφορά στην τιμή του υφάσματος
              </label>
                            </div>`;
            for (let [key, value] of Object.entries(options)) {
                if (
                    key === "id" ||
                    // key === "category" ||
                    key === "realCategory"
                )
                    nonEditableContent[0].innerHTML += `
                <div class="card">
                    <div class="card-body">
                        <div class="row w-100">
                           <div class="col-5">
                            <p class="non-editable-item-name">${
                        key[0].toUpperCase() + key.substring(1)
                    }</p>
                            </div>
                            <div class="col-7 text-end">
                            <p class="non-editable-value" data-id='${
                        key === "realCategory" ? "realCategory" : "id"
                    }'>${value}</p>
                            </div>
                        </div>
                    </div>
                </div>`;
            }
            for (let [key, value] of Object.entries(
                properties.showInFrontend
            )) {
                editableContent[0].innerHTML += `
                    <div class="card">
                        <div class="card-body">
                            <div class="row w-100">
                               <div class="col-5">
                                <p class="editable-item-name showInFrontend" >
${
                    key in propsToNames
                        ? propsToNames[key]
                        : key[0].toUpperCase() + key.substring(1)
                }
</p>
                                </div>
                                <div class="col-7 text-end form-group">
                                <input ${
                    key === "price"
                        ? 'id="input-color-price"'
                        : ""
                }  type =${
                    isNaN(Number(value)) ? "text" : "number"
                } class="editable-value form-control text-end"  value="${
                    value || priceOfParent
                }"   
${
                    key === "overwriteCategory" ||
                    (realCategory === "d" && key === "price" && value === null)
                        ? "disabled"
                        : ""
                }/>
                                </div>
                            </div>
                  ${
                    realCategory === "d" && key === "price" && value === null
                        ? priceCheckbox
                        : realCategory === "d" &&
                        key === "price" &&
                        value !== null
                            ? returnToparentPrice
                            : ""
                }
                        </div>
                    </div>
                    `;
            }
            editableContent.children().last().addClass("mb-5");
            const dontShowInFrontend = properties.dontShowInFrontend;
            if (dontShowInFrontend.dimensionsPrices) {
                editableContent[0].innerHTML += `
                    <div class="container">
                            <div class="row w-100">
                               <div class="col-12">
                                <p class="editable-item-name lead ">
                                Dimension Prices:
</p>
<p class="lead"><sub>*Every group represents the width above which a certain value is applied.</sub></p>

                                </div>
                            </div>
                    </div>
                    `;
                let index = 0;
                dontShowInFrontend.dimensionsPrices.forEach((object) => {
                    for (let [key, value] of Object.entries(object)) {
                        editableContent[0].innerHTML += `
                    <div class="card">
                        <div class="card-body">
                            <div class="row w-100">
                               <div class="col-5">
                                <p class="editable-item-name dimensionsPrices" data-index ="${index}">
${
                            key in propsToNames
                                ? propsToNames[key]
                                : key[0].toUpperCase() + key.substring(1)
                        }
</p>
                                </div>
                                <div class="col-7 text-end form-group">
                                <input type="number" class="editable-value form-control text-end" value="${value}" />
                                </div>
                            </div>
                        </div>
                    </div>
                    `;
                    }
                    index++;
                    editableContent.children().last().addClass("mb-5");
                });
            }

            if (dontShowInFrontend.fasa) {
                editableContent[0].innerHTML += `
                    <div class="container">
                            <div class="row w-100">
                               <div class="col-12">
                                <p class="editable-item-name lead">
                                Fasa*:
</p>
<p class="lead"><sub>*It represents the fabric meters needed to cover fasa its price (fabric not included).</sub></p>
                            </div>
                        </div>
                    </div>
                    `;

                for (let [key, value] of Object.entries(
                    dontShowInFrontend.fasa
                )) {
                    editableContent[0].innerHTML += `
                    <div class="card">
                    <div class="card-body">
                            <div class="row w-100">
                               <div class="col-5">
                                <p class="editable-item-name fasa">
${
                        key in propsToNames
                            ? propsToNames[key]
                            : key[0].toUpperCase() + key.substring(1)
                    }
</p>
                                </div>
                                <div class="col-7 text-end form-group">
                                <input type="number" class="editable-value form-control text-end" value="${value}" />
                                </div>
                        </div>
                        </div>
                    </div>
                    `;
                }
                editableContent.children().last().addClass("mb-5");
            }
            if (dontShowInFrontend.dimensionsBedfeet) {
                editableContent[0].innerHTML += `
                    <div class="container">
                            <div class="row w-100">
                               <div class="col-12">
                                <p class="editable-item-name lead">
                                Bed Feet Dimensions*:
</p>
<p class="lead"><sub>*Every batch of two represents the width above which a certain number of bed feet is applied.</sub></p>
                                </div>
                        </div>
                    </div>
                    `;
                let index = 0;
                dontShowInFrontend.dimensionsBedfeet.forEach((object) => {
                    for (let [key, value] of Object.entries(object)) {
                        editableContent[0].innerHTML += `
                    <div class="card">
                        <div class="card-body">
                            <div class="row w-100">
                               <div class="col-5">
                                <p class="editable-item-name dimensionsBedfeet" data-index ="${index}">
                                
${
                            key in propsToNames
                                ? propsToNames[key]
                                : key[0].toUpperCase() + key.substring(1)
                        }
</p>
                                </div>
                                <div class="col-7 text-end form-group">
                                <input type="number" class="editable-value form-control text-end" value="${value}" />
                                </div>
                            </div>
                        </div>
                    </div>
                    `;
                    }
                    index++;
                    editableContent.children().last().addClass("mb-5");
                });
            }
        }
            break;
    }
    const epanafora =
            "Επαναφορά της τιμής για όλα τα αντικείμενα του ίδιου είδους",
        enimerosi =
            "Ενημέρωσε την τιμή για όλα τα αντικείμενα του ίδιου είδους";

    $("#editable-content").children().last().removeClass("mb-5");

    if (realCategory === "c" || realCategory === "d") {
        let editableContent = $("#edit-modal .modal-body");

        editableContent[0].innerHTML += `
            <div class="container row mt-3 ${
            realCategory === "d" ? "hidden" : ""
        }" id="check-button-change-price">
                <div class="form-check ">
  <input class="form-check-input" type="checkbox" value="" id="change-all">
  <label class="form-check-label" for="change-all">
    ${
            properties.showInFrontend?.price && realCategory === "d"
                ? epanafora
                : enimerosi
        }
  </label>
</div>
            </div>
        `;

        if ($("#unlock-price").length > 0) {
            $("#unlock-price")
                .off("change")
                .on("change", function () {
                    if ($("#unlock-price").prop("checked")) {
                        $("#input-color-price").removeAttr("disabled");
                        $("#check-button-change-price").removeClass("hidden");
                    } else {
                        $("#input-color-price")
                            .attr("disabled", "disabled")
                            .val(priceOfParent);
                        $("#check-button-change-price").addClass("hidden");
                    }
                });
        }
        if ($("#parent-price").length > 0) {
            $("#parent-price").change(function () {
                if (this.checked) {
                    $("#input-color-price")
                        .attr("disabled", "disabled")
                        .attr("value", priceOfParent);
                    $("#check-button-change-price").removeClass("hidden");
                } else {
                    $("#input-color-price").removeAttr("disabled");
                    $("#check-button-change-price").addClass("hidden");
                }
            });
        }
    }
}

function handleCalcPriceResponse(response) {
    let {bedPrices, lefkaEidhPrices, debugInfo} = response;

    const discountLabelLeyka = document.querySelector("#discount-leyka"),
        startingPriceLeyka = document.querySelector("#starting-price-leyka"),
        discountLabelBed = document.querySelector("#discount-bed"),
        startingPriceBed = document.querySelector("#starting-price-bed");

    discountLabelLeyka.textContent = lefkaEidhPrices.discountPercentage + "%";
    startingPriceLeyka.textContent = lefkaEidhPrices.totalPrice + "€";
    discountLabelBed.textContent = bedPrices.discountPercentage + "%";
    startingPriceBed.textContent = bedPrices.totalPrice + "€";

    $(".price-loader").fadeOut(function () {
        $("#price-label-leyka")
            .html(lefkaEidhPrices.priceAfterDiscount + "€")
            .removeClass("hidden");
        $("#price-container-leyka").fadeIn();

        $("#price-label-bed")
            .html(bedPrices.priceAfterDiscount + "€")
            .removeClass("hidden");
        $("#price-container-bed").fadeIn();
    });


    let width = window.innerWidth;

    if (lefkaEidhPrices.discountPercentage === 0) {
        $("#discount-container-leyka").addClass("hidden");
        width > 1200
            ? $("#user-buttons-container").css("width", "400px")
            : $("#user-buttons-container").css("width", "100%");
        $("#selections-container").css("width", "358px");
        $("#starting-price-container-leyka").addClass("hidden");
    } else if (lefkaEidhPrices.discountPercentage > 0) {
        $("#discount-container-leyka").removeClass("hidden");
        width > 1200
            ? $("#user-buttons-container").css("width", "410px")
            : $("#user-buttons-container").css("width", "100%");
        $("#selections-container").css("width", "489px");
        $("#starting-price-container-leyka").removeClass("hidden");
    }

    if (bedPrices.discountPercentage === 0) {
        $("#discount-container-bed").addClass("hidden");
        width > 1200
            ? $("#user-buttons-container").css("width", "400px")
            : $("#user-buttons-container").css("width", "100%");
        $("#selections-container").css("width", "358px");
        $("#starting-price-container-bed").addClass("hidden");
    } else if (bedPrices.discountPercentage > 0) {
        $("#discount-container-bed").removeClass("hidden");
        width > 1200
            ? $("#user-buttons-container").css("width", "410px")
            : $("#user-buttons-container").css("width", "100%");
        $("#selections-container").css("width", "489px");
        $("#starting-price-container-bed").removeClass("hidden");
    }


    if (
        bedPrices.discountPercentage === 0 &&
        lefkaEidhPrices.discountPercentage === 0
    ) {
        $("#discount-container-leyka").addClass("hidden");
        $("#discount-container-bed").addClass("hidden");
        width > 1200
            ? $("#user-buttons-container").css("width", "300px")
            : $("#user-buttons-container").css("width", "100%");
        $("#selections-container").css("width", "358px");
        $("#starting-price-container-leyka").addClass("hidden");
        $("#starting-price-container-bed").addClass("hidden");
    } else if (
        bedPrices.discountPercentage > 0 &&
        lefkaEidhPrices.discountPercentage > 0
    ) {
        $("#discount-container-leyka").removeClass("hidden");
        $("#discount-container-bed").removeClass("hidden");
        width > 1200
            ? $("#user-buttons-container").css("width", "556px")
            : $("#user-buttons-container").css("width", "100%");
        $("#selections-container").css("width", "489px");
        $("#starting-price-container-leyka").removeClass("hidden");
        $("#starting-price-container-bed").removeClass("hidden");
    }

    if (width < 1200) {
        $("#selections-container").css("width", "100%");
    }

    $("#price-debug-info").html(debugInfo);
}

export async function calcPrice({connectRange = "2", dimensions = {}}) {
    $(".price-label").addClass("hidden");
    $(".price-loader").show();

    let connectRangeValue = "2",
        baseWidthInputRangeData = $(BASE_WIDTH_RANGE_SELECTOR).data(
            "ionRangeSlider"
        ).result,
        baseLengthInputRangeData = $(BASE_LENGTH_RANGE_SELECTOR).data(
            "ionRangeSlider"
        ).result.from,
        headboardWidthInputRangeData = $(HEADBOARD_WIDTH_RANGE_SELECTOR).data(
            "ionRangeSlider"
        ).result;
    // console.log('headboardWidthInputRangeData:', headboardWidthInputRangeData);
    // console.log(headboardWidthInputRangeData);
    let headboardWidth =
            connectRangeValue === "3" ? 0 : headboardWidthInputRangeData.from,
        bedWidth = connectRangeValue === "1" ? 0 : baseWidthInputRangeData.from;
    if (dimensions.bedWidth) bedWidth = dimensions.bedWidth;
    if (dimensions.bedLength) baseLengthInputRangeData = dimensions.bedLength;
    if (dimensions.headboardWidth) headboardWidth = dimensions.headboardWidth;
    let data = {
        bedWidth: bedWidth,
        bedLength: baseLengthInputRangeData,
        headboardWidth: headboardWidth,
    };
    // console.log({data});

    if (activeParentCodes?.lastUsed() === BEDS) {
        const {propMaxWidth, propMaxLength} =
            activeParentCodes.getMaxBedDimensions();
        data.headboardWidth = 0;
        data.bedWidth = propMaxWidth;
        data.bedLength = propMaxLength;
    }

    let price = await postRequest("/mediastrom/calc-price/", {
        sessionPrefix: sessionPrefix,
        dimensions: data,
        connectRange: "2" /* connectRange.value */,
    });

    handleCalcPriceResponse(price);
}

export function disableBedFeet(activeName = "") {
    /* If the Base 'Storage' or 'Fit' is selected then disable all bed legs except 'Athena' and trigger a click on
      'Athena' */
    if (
        document.querySelector(
            `#category-a [data-name="${BASES_MENU_NAME}"].active`
        ) ||
        document.querySelector(`#category-a [data-name="${BEDS}"].active`) ||
        activeName
    ) {
        let activeBase = document.querySelector("#category-b .active"),
            name = activeName
                ? activeName
                : activeBase?.dataset?.name ?? "Basic";

        if (name === "Storage" || name === "Sorento Storage") {
            let bedFeetArray = Array.from(
                document.querySelectorAll("#bed-feet .menu-item")
            );
            bedFeetArray.forEach((item) => {
                if (item.dataset.name === "Athena") {
                    item.classList.add("active");
                } else {
                    item.classList.add("disabled");
                    item.classList.remove("active");
                }
            });
            return document.querySelector("#bed-feet .menu-item.active").dataset
                .id;
        } else {
            $("#bed-feet .menu-item").removeClass("disabled");
        }

        // $("#dimensions-container > div.dimensions.container.bases.mb-3").removeClass("hidden");
        // $("#dimensions-container > div.dimensions.container.headboards").addClass("hidden");
    } else if (
       (document.querySelector(
            `#category-a [data-name="${HEADBOARD_MENU_NAME}"].active`
        ) || document.querySelector(
            `#category-a [data-name="${LEYKA}"].active`
        ))&&
        global.menuStateNames
    ) {
        for (const values of Object.values(global.menuStateNames)) {
            if (values.a.name === BASES_MENU_NAME) {
                if (values.b.name === "Storage") {
                    let bedFeetArray = Array.from(
                        document.querySelectorAll("#bed-feet .menu-item")
                    );
                    bedFeetArray.forEach((item) => {
                        if (item.dataset.name === "Athena") {
                            item.classList.add("active");
                        } else {
                            item.classList.add("disabled");
                            item.classList.remove("active");
                        }
                    });
                } else {
                    $("#bed-feet .menu-item").removeClass("disabled");
                }
                if (values.b.name === "Fit") {
                    $("#bed-foot").addClass("disabled");
                } else {
                    $("#bed-foot").removeClass("disabled");
                }
            }
            if (global.isBedsOn) {
                $("#bed-foot").addClass("disabled");
            }
        }
       

    }
    return document.querySelector("#bed-feet .menu-item.active")?.dataset?.id;
}

function hideBackgroundButton() {
    $('#category-a [data-name="Background"]').hide();
}

function scrollMenuToTop() {
    document.querySelector(".grid-column1").scrollTop = 0;
}

function disappearContextMenu() {
    if (document.querySelector("#context-menu-container")) {
        $("#context-menu-container").addClass("hidden");
    }
}

function handleRightClickMenu(e) {
    e.preventDefault();
    const {clientX: mouseX, clientY: mouseY} = e;
    $("#context-menu-container")
        .css("top", mouseY)
        .css("left", mouseX)
        .css("z-index", 10000)
        .removeClass("hidden");
    $("#context-menu-edit-item").attr("data-set", JSON.stringify(this.dataset));
}

function handleEditModalBuild() {
    const edit = document.querySelector("#context-menu-edit-item");
    $("#edit-modal .modal-body").html("");
    if (edit.dataset.set === "{}") {
        // console.log(edit.dataset.set);
        return null;
    } else {
        //    create the inner HTML content
        let set = JSON.parse(edit.dataset.set);
        createModalContent(set);
    }
}

/* ************************* Listener to toggle class hidden for pillows ****************************** */
document
    .querySelector("#add-pillows > label > input")
    .addEventListener("change", function () {
        if (this.checked) {
            $("#tivoli-subcategories").removeClass("hidden");
            this.setAttribute("checked", true);
        } else {
            document.querySelector('[data-prop-name="Tivoli"]').click();
            $("#tivoli-subcategories").addClass("hidden");
            this.setAttribute("checked", false);
        }
    });

function addListenersOnButtons() {
    // set save button of edit-properties-modal
    const saveButton = $(
        "#edit-modal > div > div > div.modal-footer > button.btn.btn-primary"
    );
    // set Url to call when savebutton is pressed
    const urlChangeProperties = `/core/update-props-of-object/`;

    saveButton.click(async function () {
        const body = JSON.stringify({
            category: document.querySelector("[data-id='realCategory']")
                .textContent,
            id: document.querySelector("[data-id='id']").textContent,
            properties: createObjectForPostRequest("#edit-modal"),
            changeAll: !!$("#change-all").prop("checked"),
        });
        // console.log(body);
        const changeUrlOpts = {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": $("input[name=csrfmiddlewaretoken]").val(),
            },
            body,
        };

        let response = await fetch(urlChangeProperties, changeUrlOpts);
        let result = await response.json();
        // console.log(result);
        if (result.success) {
            const Toast = Swal.mixin({
                toast: true,
                text: "Αποθηκεύσατε επιτυχώς τις αλλαγές σας!",
                position: "bottom-right",
                iconColor: "white",
                customClass: {
                    popup: "colored-toast",
                },
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            });
            await Toast.fire({
                icon: "success",
                title: "Hooray!",
            });
        } else {
            const Toast = Swal.mixin({
                toast: true,
                text: "Κάτι πήγε στραβά!",
                position: "bottom-right",
                iconColor: "white",
                customClass: {
                    popup: "colored-toast",
                },
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            });
            await Toast.fire({
                icon: "error",
                title: "Oops!",
            });
        }
    });

    // let headboardsId = document.querySelector('#category-a [data-name=' + HEADBOARD_MENU_NAME + ']').dataset.id;
    //add listener to create new data for excel file
    const pngClicked = document.querySelector("#form-get-pdf");
    const imageDownloadClicked = document.querySelector("#form-get-image");
    let sessionPrefix = JSON.parse(
        document.getElementById("sessionPrefix").textContent
    );

    const leykaSwitchButton = document.querySelector("#leyka-checkbox");
    const bedSwitchButton = document.querySelector("#krevati-checkbox");

    pngClicked.addEventListener("click", async function (e) {
        e.preventDefault();
        let leykaDiscount = true;
        let bedDiscount = true;

        const discountContainerBed = document.querySelector('#discount-container-bed');
        const discountContainerLeyka = document.querySelector('#discount-container-leyka');

        if (discountContainerBed && (discountContainerBed.classList.contains('hidden') || getComputedStyle(discountContainerBed).display === 'none')) {
            leykaDiscount = false;
        }

        if (discountContainerLeyka && (discountContainerLeyka.classList.contains('hidden') || getComputedStyle(discountContainerLeyka).display === 'none')) {
            bedDiscount = false;
        }

        console.log(leykaDiscount, bedDiscount);
        if (leykaSwitchButton.checked || bedSwitchButton.checked) {
            let sessionPrefix = JSON.parse(
                document.getElementById("sessionPrefix").textContent
            );
            // console.log(sessionPrefix);
            let url = `/mediastrom/get-pdf/`;
            await getRequest(url, {sessionPrefix, leykaDiscount, bedDiscount});
            await sendStatisticToServer("download_pdf");
        } else {
            Swal.fire({
                toast: true,
                icon: "error",
                title: "Η λειτουργία του PDF είναι απενεργοποιημένη επειδή δεν έχετε υπολογίσει κάποιο προϊόν στην τιμή.",
                position: "top",
                showConfirmButton: false,
                timer: 4500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                },
            });
        }
    });

    imageDownloadClicked.addEventListener("click", async function () {
        await sendStatisticToServer("download_image");
    });

    const icon = document.querySelector(".icon"),
        burgerMenu = document.querySelector("#burger-menu");

    icon.addEventListener("click", (event) => {
        icon.classList.toggle("open");
        if (burgerMenu.classList.contains("hidden")) {
            burgerMenu.style.zIndex = "-1";
            burgerMenu.classList.toggle("hidden");
            burgerMenu.classList.toggle("animate__slideOutDown");
            burgerMenu.classList.toggle("animate__slideInUp");
            setTimeout(function () {
                burgerMenu.style.zIndex = "initial";
            }, 800);
        } else {
            burgerMenu.style.zIndex = "-1";
            burgerMenu.classList.toggle("animate__slideOutDown");
            burgerMenu.classList.toggle("animate__slideInUp");
            setTimeout(function () {
                burgerMenu.classList.toggle("hidden");
            }, 800);
        }
    });

    window.addEventListener("resize", function () {
        let width = window.innerWidth;
        let height = window.innerHeight;
        if (width > 1200 && height > width) {
            $(".image-display").css("background-size", "contain");
        } else {
            $(".image-display").css("background-size", "cover");
        }
    });

    $("#connect-range").on("change", async function (e) {
        if (e.target.value === "1") {
            $("#category-a [data-name=" + HEADBOARD_MENU_NAME + "]")
                .removeClass("disabled")
                .trigger("click");
            $("#category-a [data-name=" + BASES_MENU_NAME + "]").addClass(
                "disabled"
            );
            updateSlider(BASE_WIDTH_RANGE_SELECTOR, {from: 0});
            updateSlider(BASE_LENGTH_RANGE_SELECTOR, {from: 0});
            if (
                $(".dimensions.container.headboards").hasClass("hidden") &&
                $("#dimensions").hasClass("active")
            ) {
                $(".dimensions.container.bases").addClass("hidden");
                $(".dimensions.container.headboards").removeClass("hidden");
            }
            document.querySelector("#check-to-connect-label").textContent =
                "Headboard only";
        } else if (e.target.value === "3") {
            $("#category-a [data-name=" + BASES_MENU_NAME + "]")
                .removeClass("disabled")
                .trigger("click");
            // Trigger
            $("#category-a [data-name=" + HEADBOARD_MENU_NAME + "]").addClass(
                "disabled"
            );
            updateSlider(HEADBOARD_WIDTH_RANGE_SELECTOR, {from: 0});
            document.querySelector("#check-to-connect-label").textContent =
                "Base only";
            if (
                $(".dimensions.container.bases").hasClass("hidden") &&
                $("#dimensions").hasClass("active")
            ) {
                $(".dimensions.container.headboards").addClass("hidden");
                $(".dimensions.container.bases").removeClass("hidden");
            }
        } else {
            $(
                "#category-a [data-name=" + HEADBOARD_MENU_NAME + "]"
            ).removeClass("disabled");
            $("#category-a [data-name=" + BASES_MENU_NAME + "]").removeClass(
                "disabled"
            );
            document.querySelector("#check-to-connect-label").textContent =
                "Base & Headboards";
            $("#category-a .menu-item.active").trigger("click");
        }
        await calcPrice({connectRange});
    });

    $("#bed-foot").on("click", function () {
        $("#category-a .menu-item").removeClass("active");
        $("#category-b").addClass("hidden");
        document.querySelector(".fake-sub-menu-diakosmitika").style.display = "none";
        $("#bed-foot").addClass("active");
        $("#bed-feet").removeClass("hidden");
        // When the Bedfeet menu is enabled then hide the Fabrics menu.
        enableDisableFabricsMenus(false);
        // When the Bedfeet menu is enabled then disable the dimensions menu.
        $(".dimensions.container").addClass("disabled");
    });

    // Make select option disappear when dimensions or bed feet active.
    document
        .querySelector("#bed-attribute > div")
        .addEventListener("click", function () {
            let selection = document.querySelector("#property-selections");
            document.querySelector("#textile").classList.contains("active")
                ? selection.classList.remove("hidden")
                : selection.classList.add("hidden");
        });
    document
        .querySelector("[data-name='Λευκά Είδη']")
        .addEventListener("click", function () {
            document
                .querySelector("#property-selections")
                .classList.add("hidden");
        });

    // Listener for Base width range input.
    $(BASE_WIDTH_RANGE_SELECTOR).ionRangeSlider({
        onFinish: async function (data) {
            let baseWidth = data.from;
            restrictionsAccordingToBaseWidth(baseWidth);
            // updateSlider(BASE_WIDTH_RANGE_SELECTOR, rangeObj);
            await calcPrice({
                connectRange: document.querySelector("#connect-range"),
            });
        },
    });

    // Listener for Base length range input.
    $(BASE_LENGTH_RANGE_SELECTOR).ionRangeSlider({
        onFinish: async function (data) {
            let from = data.from;
            restrictionsAccordingToBaseLength(from);
            await calcPrice({
                connectRange: document.querySelector("#connect-range"),
            });
        },
    });

    // Listener for Head Board width range input.
    $(HEADBOARD_WIDTH_RANGE_SELECTOR).ionRangeSlider({
        onFinish: async function (data) {
            // Restrictions according to base width.
            let from = data.from,
                active = undefined;
            if ($("#tivoli-subcategories .menu-item.active").length !== 0) {
                active = $("#tivoli-subcategories .menu-item.active");
            }
            restrictionsAccordingToHeadBoardWidth(from, active);
            await calcPrice({
                connectRange: document.querySelector("#connect-range"),
            });
        },
    });

    $("#category-a .menu-item").on("click", function () {
        document.querySelector("#tivoli-subcategories").innerHTML = "";
        if (this.dataset.name !== "Κεφαλάρι" || this.id === "bed-foot") {
            document.querySelector("#add-pillows").classList.add("hidden");
            document.querySelector(".add-pillows").classList.add("hidden");
        } else {
            let kefalariId = global.menuData.find(
                ({name}) => name === "Κεφαλάρι"
            ).id;
            let activeKefalari = global.menuStateNames[kefalariId]["b"]["name"];
            // console.log(activeKefalari, global.menuStateNames);
            if (activeKefalari === "Tivoli") {
                document
                    .querySelector("#add-pillows")
                    .classList.remove("hidden");
                document
                    .querySelector(".add-pillows")
                    .classList.remove("hidden");
                if (
                    document.querySelector("#add-pillows > label > input")
                        .checked
                ) {
                    document
                        .querySelector("#tivoli-subcategories")
                        .classList.remove("hidden");
                }
            } else {
                document
                    .querySelector("#tivoli-subcategories")
                    .classList.add("hidden");
                document.querySelector("#add-pillows").classList.add("hidden");
            }
        }
        if (this.id === "bed-foot") {
            document.querySelector("#pillow-dimantions").style.display = "none";
            document.querySelector("#leyka-dimantions").style.display = "none";
            // document.querySelector(".leyka-checkbox-container").style =
            //     "display: none";
            // document.querySelector(".krevati-checkbox-container").style =
            //     "display: flex";
            $('.vscomp-clear-button').trigger('click');
            $("#show-hide").addClass("hidden");
        } else {
            $("#show-hide").removeClass("hidden");
        }
    });
}

export function handleCategoryA(callbackForBase, callbackForHeadboard) {
    $(".dimensions.container").removeClass("disabled");

    let catABaseMenu = $("#category-a [data-name= " + BASES_MENU_NAME + "]"),
        catAHeadboardsMenu = $(
            "#category-a [data-name= " + HEADBOARD_MENU_NAME + "]"
        ),
        catABedsMenu = $("#category-a [data-name= " + BEDS + "]"),
        bedTextContainers = $(".bed-text-container"),
        activeBItem = $("#category-b .active")[0];

    bedTextContainers.addClass("hidden");
    if (catABaseMenu.hasClass("active")) {
        // console.log(needed.showLayoutState());
        $(".dimensions.bases").removeClass("hidden");
        $(".dimensions.headboards").addClass("hidden");
    } else if (catAHeadboardsMenu.hasClass("active")) {
        $(".dimensions.bases").addClass("hidden");
        $(".dimensions.headboards").removeClass("hidden");
    }
    if ($(`#tivoli-subcategories .menu-item.active`).length) {
        $(`#category-b [data-name="Tivoli"][data-category="b"]`).addClass(
            "sub-category-active"
        );
    }

    if (catABaseMenu.hasClass("active")) {
        callbackForBase(this);
    } else if (catAHeadboardsMenu.hasClass("active")) {
        callbackForHeadboard();
    }
    if (catABedsMenu.hasClass("active")) {
        $(".dimensions.headboards").addClass("hidden");
        $(".dimensions.bases").addClass("hidden");
        bedTextContainers.removeClass("hidden");
    }
    activeParentCodes.setLastUsed(
        document.querySelector("#category-a .menu-item.active")
    );
}

function setListenersOnRebuild(nextCategory) {
    if (JSON.parse($("#is_superuser").text())) {
        $(".menu-item").off("contextmenu").contextmenu(handleRightClickMenu);
        document
            .querySelector("body")
            .removeEventListener("click", disappearContextMenu);
        document
            .querySelector("body")
            .addEventListener("click", disappearContextMenu);
        $("#context-menu-edit-item").off("click").click(handleEditModalBuild);
    }

    document.querySelector("#headboards-tooltip")?.classList.add("hidden");
    // Set listener to base change.
    if (nextCategory === "a" || nextCategory === undefined) {
        // Set listener to base change.

        $("#category-b .menu-item").on("click", function (e) {
            if (
                !$(`#category-a .menu-item[data-name="${LEYKA}"]`).hasClass(
                    "active"
                )
            ) {
                activeParentCodes.setCode(this);

                const parent = document.querySelector(
                    "#category-a .menu-item.active"
                );
            }

            if (
                $("#category-a [data-name=" + BASES_MENU_NAME + "]").hasClass(
                    "active"
                )
            ) {
                setMinMaxValueAccordingToBase(this);
                if (this.dataset.name.toLowerCase() === "fit") {
                    if ($("#bed-foot").hasClass("active")) {
                        $("#textile").trigger("click");
                    }
                }
            } else if (
                $(
                    "#category-a [data-name=" + HEADBOARD_MENU_NAME + "]"
                ).hasClass("active")
            ) {
                $("#tivoli-subcategories .menu-item").removeClass("active");

                // $(`#images-container > div[data-div-name="base_bed_set"]`)[0].style.display = `initial`;
                restrictionsAccordingToHeadBoardWidth(e, $(this));
                setMinMaxValueAccordingToHeadboard();
            } else if (
                $("#category-a [data-name=" + BEDS + "]").hasClass("active")
            ) {
                $(".dimensions.headboards").addClass("hidden");
                $(".dimensions.bases").addClass("hidden");

                let activeBItem = $("#category-b .active")[0],
                    bedText = $("#bed-text");
                bedText.text(activeBItem.dataset.propBedText);
            }

            if (this.dataset.propName === "Tivoli") {
                $("#add-pillows").removeClass("hidden");
                document
                    .querySelector(".add-pillows")
                    .classList.remove("hidden");
                document.querySelector("#add-pillows input").checked = false;

                if (this.dataset?.propSubcategoryname) {
                    $("#category-b .menu-item[data-name='Tivoli']").addClass(
                        "sub-category-active"
                    );
                } else {
                    $("#category-b .menu-item[data-name='Tivoli']").removeClass(
                        "sub-category-active"
                    );
                }
            } else {
                $("#category-b .menu-item[data-name='Tivoli']").removeClass(
                    "sub-category-active"
                );
                $("#add-pillows").addClass("hidden");
                document.querySelector(".add-pillows").classList.add("hidden");
                document
                    .querySelector("#tivoli-subcategories")
                    .classList.add("hidden");
            }

            let selections = document.querySelectorAll(
                ".vscomp-options .selected"
            );
            if (selections.length > 0) {
                setTimeout(findSelections, 200);
            }
        });
        // Listener for the Tivoli subcategories
    } else if (nextCategory === "b") {
        $("#tivoli-subcategories .menu-item").on("click", function (e) {
            $("#category-b .menu-item[data-name='Tivoli']").addClass(
                "sub-category-active"
            );
            $("#category-b .menu-item").removeClass("active");
            setMinMaxValueAccordingToHeadboard($(this));
            restrictionsAccordingToHeadBoardWidth(e, $(this));
        });
    }
    categoryCMenuItemListener();
}

function categoryCMenuItemListener() {
    $("#category-c .menu-item").on("click", async function () {
        const activeA = document.querySelector("#category-a .menu-item.active");

        if (activeA.dataset.propShowfabricfiltersinfrontend) {
            findSelections();
            // Get categories d dataset category and subcategory
            let selectedValues = document.querySelectorAll(
                ".vscomp-dropbox-container.pop-comp-wrapper.position-bottom > div > div.vscomp-options-container > div.vscomp-options-list > div .selected"
            );
            setTimeout(function () {
                const categoryDMenuItems = document.querySelectorAll(
                    "#category-d .menu-item"
                );
                categoryDMenuItems.forEach((item) => {
                    let {propCategory, propSubcategory} = item.dataset;
                    for (let value of selectedValues) {
                        let val = value.dataset.value;
                        if (val === propCategory || val === propSubcategory) {
                            item.style.display = "initial";
                        } else {
                            item.style.display = "none";
                        }
                    }
                });
            }, 250);
        }
    });
}

function extraActions(t) {
    if (t.attr("data-category") === "a") {
        $("#bed-feet").addClass("hidden");
        $("#category-b").removeClass("hidden");
        enableDisableFabricsMenus(true);
    }
}

function enableDisableFabricsMenus(enableDisable) {
    let class_ = "hidden";
    if (enableDisable) {
        $("#bed-attribute").removeClass(class_);
        $("#property-selections").removeClass(class_);
        $("#category-c").removeClass(class_);
        $("#category-d").removeClass(class_);
        $("#breadcrumbs").removeClass(class_);
    } else {
        $("#bed-attribute").addClass(class_);
        $("#property-selections").addClass(class_);
        $("#category-c").addClass(class_);
        $("#category-d").addClass(class_);
        $("#breadcrumbs").addClass(class_);
    }
}

function hide45X45Pillows() {
    document.querySelector(
        "[data-div-name='pillows_diakosmitika']"
    ).style.display = "none";
    document.querySelector(
        "[data-div-name='pillows_diakosmitika_shadow']"
    ).style.display = "none";
    document.querySelector(
        "[data-div-name='pillows_diakosmitika_2']"
    ).style.display = "";
    document.querySelector(
        "[data-div-name='pillows_diakosmitika_shadow_2']"
    ).style.display = "";
}

function hide30X50Pillows() {
    document.querySelector(
        "[data-div-name='pillows_diakosmitika']"
    ).style.display = "";
    document.querySelector(
        "[data-div-name='pillows_diakosmitika_shadow']"
    ).style.display = "";
    document.querySelector(
        "[data-div-name='pillows_diakosmitika_2']"
    ).style.display = "none";
    document.querySelector(
        "[data-div-name='pillows_diakosmitika_shadow_2']"
    ).style.display = "none";
}

// This function handles the showing and hiding of pillows based on radio button selection.
function showHidePillows(diakosmitikaCheckbox) {
    // Loop through all elements with the name 'group1'
    document.getElementsByName("group1").forEach((item) => {
        if (diakosmitikaCheckbox) {
            // Check if the radio button is checked and has the value '45x45'
            if (item.checked && item.value === "45x45") {
                // Call the hide30X70Pillows function to hide pillows for the other option
                hide30X50Pillows();
            } else if (item.checked && item.value === "30x50") {
                // Call the hide45X45Pillows function to hide pillows for the other option
                hide45X45Pillows();
            }
        }

        // Add a click event listener to the radio button
        item.addEventListener("change", async function () {
            // Check if the radio button is checked
            const nodeList = document.querySelectorAll(
                ".leyka-menu-checkboxes"
            );
            if (nodeList[nodeList.length - 1].checked) {
                $(".price-label").addClass("hidden");
                $(".price-loader").show();
                // Check the value of the clicked radio button
                if (this.value === "45x45") {
                    // Call the hide30X70Pillows function to hide pillows for the other option
                    hide30X50Pillows();
                    // Make an asynchronous POST request to update the selected option
                    const response = await postRequest(
                        "/update-session-price-layout/",
                        {
                            sessionPrefix,
                            pillowsRadioOption: "45x45",
                        }
                    );
                    handleCalcPriceResponse(response.prices);
                } else {
                    // Call the hide45X45Pillows function to hide pillows for the other option
                    hide45X45Pillows();
                    // Make an asynchronous POST request to update the selected option
                    const response = await postRequest(
                        "/update-session-price-layout/",
                        {
                            sessionPrefix,
                            pillowsRadioOption: "30x50",
                        }
                    );
                    handleCalcPriceResponse(response.prices);
                }
                //changeSrcOfImageZoom();

            }
        });
    });
}

export function includeLeykaInPrice() {
    document
        .querySelector("#leyka-checkbox")
        .addEventListener("change", async (e) => {
            $(".price-label").addClass("hidden");
            $(".price-loader").show();
            const response = await postRequest(
                "/update-session-price-layout/",
                {
                    sessionPrefix,
                    lefkaEidhCheckboxesState: {
                        [LEFKA_EIDH_GENERAL_PRICE_CHECKBOX]: e.target.checked,
                    },
                }
            );
            handleCalcPriceResponse(response.prices);
        });
}

export function includeKrevatiInPrice() {
    document
        .querySelector("#krevati-checkbox")
        .addEventListener("change", async (e) => {
            $(".price-label").addClass("hidden");
            $(".price-loader").show();

            const response = await postRequest(
                "/update-session-price-layout/",
                {
                    sessionPrefix,
                    lefkaEidhCheckboxesState: {
                        [BED_GENERAL_PRICE_CHECKBOX]: e.target.checked,
                    },
                }
            );
            handleCalcPriceResponse(response.prices);
        });
}

function showLefkaEidhCheckboxToast(constName, showHide) {
    if (
        (constName === KATOSENTONO_CONST_NAME ||
            constName === MAKSILARIA_CONST_NAME) &&
        !showHide
    ) {
        let lefkoEidosMsg,
            showSwal = false;
        if (
            constName === KATOSENTONO_CONST_NAME &&
            !global.katosentonoInfoMsgShown
        ) {
            global.katosentonoInfoMsgShown = true;
            showSwal = true;
            lefkoEidosMsg =
                "Το κατοσέντονο έχει αφαιρεθεί από την συνολική τιμή αλλά εξακολουθεί να φαίνεται στην εικόνα για αισθητικούς λόγους.";
        } else if (
            constName === MAKSILARIA_CONST_NAME &&
            !global.maksilariaInfoMsgShown
        ) {
            global.maksilariaInfoMsgShown = true;
            showSwal = true;
            lefkoEidosMsg =
                "Οι μαξιλαροθήκες έχουν αφαιρεθεί από την συνολική τιμή αλλά εξακολουθούν να φαίνονται στην εικόνα για αισθητικούς λόγους.";
        }

        if (showSwal) {
            Swal.fire({
                toast: true,
                icon: "info",
                title: lefkoEidosMsg,
                position: "bottom-left",
                showConfirmButton: false,
                timer: 4500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.onmouseenter = Swal.stopTimer;
                    toast.onmouseleave = Swal.resumeTimer;
                },
            });
        }
    }
}

export function listenerForCheckboxes(box) {
    // Remove existing event listeners
    box.removeEventListener("change", box.changeHandler);

    // Define the event listener function
    box.changeHandler = async (e) => {
        let isMenuItemActive =
            e.target.parentNode.parentNode.classList.contains("active");
        if (!e.target.checked || (e.target.checked && isMenuItemActive)) {
            $(".price-label").addClass("hidden");
            $(".price-loader").show();

            const response = await postRequest(
                "/update-session-price-layout/",
                {
                    sessionPrefix,
                    lefkaEidhCheckboxesState: {
                        [e.target.dataset.parentConstname]: e.target.checked,
                    },
                }
            );
            handleCalcPriceResponse(response.prices);

            // Sync the checkboxes (check/uncheck them)
            syncLeykaEidhCheckboxes(response.lefkaEidhCheckboxesState);

            // Show info messages to the user.
            showLefkaEidhCheckboxToast(
                e.target.dataset.parentConstname,
                e.target.checked
            );
        } else {
            // Trigger the click event on the menu item.
            e.target.parentNode.parentNode.click();
        }
    };
    // Add the event listener
    box.addEventListener("change", box.changeHandler);
}

function showHideLefkoEidos(constName, showHide) {
    switch (constName) {
        case DIAKOSMITIKA_MAKSILARIA_CONST_NAME:
            if (showHide) {
                if (document.querySelector("#pillow-option-1").checked) {
                    showHideDivs(true, [
                        "pillows_diakosmitika_shadow_2",
                        "pillows_diakosmitika_2",
                    ]);
                    showHideDivs(false, [
                        "pillows_diakosmitika_shadow",
                        "pillows_diakosmitika",
                    ]);
                } else {
                    showHideDivs(true, [
                        "pillows_diakosmitika_shadow",
                        "pillows_diakosmitika",
                    ]);
                    showHideDivs(false, [
                        "pillows_diakosmitika_shadow_2",
                        "pillows_diakosmitika_2",
                    ]);
                }
            } else {
                showHideDivs(false, [
                    "pillows_diakosmitika_shadow",
                    "pillows_diakosmitika",
                    "pillows_diakosmitika_shadow_2",
                    "pillows_diakosmitika_2",
                ]);
            }
            break;
        case KATOSENTONO_CONST_NAME:
            showHideDivs(true, ["katosentono_shadow", "katosentono"]);
            if (showHide) {
                showHideDivs(false, [
                    "set_sentonia_katosentono",
                    "set_sentonia_panosentono",
                ]);
            }
            break;
        case KOUVERLI_CONST_NAME:
            showHideDivs(showHide, ["kouverli_shadow", "kouverli"]);
            if (showHide) {
                showHideDivs(false, [
                    "paplomatothiki_shadow",
                    "paplomatothiki",
                ]);
            }
            break;
        case MAKSILARIA_CONST_NAME:
            // We are not hiding anything here.
            break;
        case PANOSENTONO_CONST_NAME:
            showHideDivs(showHide, ["panosentono"]);
            if (showHide) {
                showHideDivs(true, [
                    "panosentono",
                    "katosentono_shadow",
                    "katosentono",
                ]);
                showHideDivs(false, [
                    "set_sentonia_katosentono",
                    "set_sentonia_panosentono",
                ]);
            }
            break;
        case PAPLOMATOTHIKI_CONST_NAME:
            showHideDivs(showHide, ["paplomatothiki_shadow", "paplomatothiki"]);
            if (showHide) {
                showHideDivs(false, ["kouverli_shadow", "kouverli"]);
            }
            break;
        case SET_SENTONIA_CONST_NAME:
            showHideDivs(showHide, [
                "set_sentonia_katosentono",
                "set_sentonia_panosentono",
            ]);
            if (showHide) {
                showHideDivs(false, [
                    "katosentono_shadow",
                    "katosentono",
                    "panosentono",
                ]);
            } else {
                showHideDivs(true, ["katosentono_shadow", "katosentono"]);
            }
            break;
    }
}

async function updateLayoutStateToBackend() {
    // Update layoutState to backend.
    const response = await postRequest("/update-layout-state/", {
        sessionPrefix,
        layoutState: global.layoutState,
    });
}

function syncLeykaEidhCheckboxes(lefkaEidhCheckboxesState) {
    // Loop through lefkaEidhCheckboxesState and sync the checkboxes.
    for (const [key, value] of Object.entries(lefkaEidhCheckboxesState)) {
        const checkbox = document.querySelector(
            `[data-parent-constname="${key}"].leyka-menu-checkboxes`
        );
        if (checkbox) {
            checkbox.checked = value;
        }
        // Show/Hide the divs
        showHideLefkoEidos(key, value);
    }
    //changeSrcOfImageZoom();
    updateLayoutStateToBackend();
}

function showHideChat() {
    const chat = document.querySelector(".ai-chat-container");
    const chatButton = document.querySelector("#ai-chat-btn");

    chatButton.addEventListener("click", function () {
        chat.classList.toggle("hidden");
        // if (chat.style.display === "none") {
        //     chat.style.display = "flex";
        //     chat.classList.add = "chat-animation";
        // } else {
        //     chat.style.display = "none";
        //     chat.classList.remove = "chat-animation";
        // }
    });
}

// Function to handle the form submission
function submitAiForm() {
    // Select the form element with class "ai-chat-form"
    const form = document.querySelector(".ai-chat-form");

    // Select the file input element within the form with class "ai-chat-input"
    const input = document.querySelector(".ai-chat-input");

    input.addEventListener("change", function () {
        // Get the first file selected by the user
        const file = input.files[0];
        document.querySelector(".ai-chat-input-label").textContent = file.name;
    });

    // Add an event listener for the form's submit event
    form.addEventListener("submit", function (e) {
        // Prevent the default form submission behavior
        e.preventDefault();

        // Get the first file selected by the user
        const file = input.files[0];

        // Create a new FileReader instance to read the file
        const reader = new FileReader();

        // Set up an event listener for when the file reading is complete
        reader.onload = function (event) {
            // Retrieve the data URL representing the file content in Base64
            const dataUrl = event.target.result;
            addTypingToChat();
            scrollDownInsideElement();
            //fakeFor1dollar();
            requestGPT4(dataUrl);
        };

        // Read the file as a data URL
        reader.readAsDataURL(file);
    });
}

function addTextToChat(text) {
    const p = document.createElement("p");
    const img = document.createElement("img");
    const div = document.createElement("div");
    div.classList.add("ai-chat-message");
    img.src = aiPic;
    p.innerText = text;

    div.appendChild(img);
    div.appendChild(p);

    document.querySelector(".ai-chat").appendChild(div);
}

function removeTypingFromChat() {
    const element = document?.querySelector(".ai-chat-typing");
    element?.remove();
}

function addTypingToChat() {
    const p = document.createElement("p");
    const img = document.createElement("img");
    const div = document.createElement("div");
    div.classList.add("ai-chat-typing");
    img.src = aiPic;
    p.innerText = "Typing...";

    div.appendChild(img);
    div.appendChild(p);

    document.querySelector(".ai-chat").appendChild(div);
}

function scrollDownInsideElement() {
    var element = document.querySelector(".ai-chat");
    if (element) {
        // Scroll to the bottom
        element.scrollTop = element.scrollHeight;
    }
}


// Function to build radio buttons based on the provided data
function buildRadioButtons(data) {
    // Select the container for the radio buttons
    let element = document.querySelector(".radio-leyka-container");

    // Remove all existing children of the container
    while (element.firstChild) {
        element.removeChild(element.firstChild);
    }

    // If the data does not contain dimensionsPrices, exit the function
    if (!data?.dontShowInFrontend?.dimensionsPrices) {
        return;
    }

    // Extract dimensionsPrices from the data
    const dimensions = data.dontShowInFrontend.dimensionsPrices;

    // Show the container
    document.querySelector("#show-hide").style.display = "block";
    // Iterate over each dimension
    for (const [key, value] of Object.entries(dimensions)) {
        // Create div, input and label elements
        const div = document.createElement("div");
        const input = document.createElement("input");
        const label = document.createElement("label");

        // Set properties for the input element
        input.type = "radio";
        input.name = "dimensions";
        input.value = value.dimensions;
        input.id = `lefka-eidh-radio-${value.code}`;

        // Add class to the input element
        input.classList.add("dimensions-radio-button");

        // Add event listener to the input element
        input.addEventListener("change", async function () {
            // On change, send the selected dimension to the server and update selectedDimension and selectedPrice
            sendLeykoDimentionsToServer(value.code, value.dimensions);
            $(".price-label").addClass("hidden");
            $(".price-loader").show();
        });

        // Set properties for the label element
        label.htmlFor = `lefka-eidh-radio-${value.code}`;
        label.classList.add("dimensions-radio-button-label");
        label.textContent = value.dimensions;

        // Append input and label to the div
        div.appendChild(input);
        div.appendChild(label);

        // Append the div to the container
        document.querySelector(".radio-leyka-container").appendChild(div);
    }
}

// Asynchronous function to send selected dimensions to the server
async function sendLeykoDimentionsToServer(code, dimensions) {
    // Get the active item from the DOM
    const activeItem = document.querySelector("#category-b .active").dataset
        .propConstname;

    // If the active item is diakosmitika_maksilaria, we don't want to send the dimensions to the server
    // because diakoismatika_maksilaria are handeled differently by an other function
    if (activeItem === "diakosmitika_maksilaria") {
        return;
    }
    // Send a POST request to the server with the sessionPrefix and data
    // The data includes the constant name of the active item, code, dimensions, and price
    const response = await postRequest("/mediastrom/lefka-eidh-dimensions/", {
        sessionPrefix,
        data: {
            constName: activeItem,
            code,
            dimensions,
        },
    });
    // The response from the server is handled by the handleCalcPriceResponse function
    handleCalcPriceResponse(response.prices);
}

async function requestGPT4(url) {
    const key = process.env.API_KEY;
    const openai = new OpenAI({apiKey: key, dangerouslyAllowBrowser: true});

    const response = await openai.chat.completions.create({
        model: "gpt-4-vision-preview",
        max_tokens: 4096,
        messages: [
            {
                role: "user",
                content: [
                    {type: "text", text: "What’s in this image?"},
                    {
                        type: "image_url",
                        image_url: {
                            url: url,
                        },
                    },
                ],
            },
        ],
    });

    addTextToChat(response.choices[0]);

    removeTypingFromChat();
}

function checkUrlForLeykaEidh() {
    // Get the url
    const url = window.location.href;

    // If the url contains the string "leyka-eidh", return true
    if (url.includes("#linen_storiz")) {
        document.querySelector("[data-name='Λευκά Είδη']").click();
    }
}

function changeSrcOfImageZoom() {
    let element = document.getElementById(`images-container-grid`);
    // Create an image element
    let img = document.getElementById('magnify-image');
    let glass = document.querySelector(".img-magnifier-glass");
    glass.style.display = "none";

    html2canvas(element).then(canvas => {

        // Set the image source to the base64 encoded string
        img.src = canvas.toDataURL('image/png');

        glass.style.display = "initial";

        glass.style.backgroundImage = "url('" + img.src + "')";
    });
}

function magnify(imgID, zoom) {
    let element = document.getElementById(`images-container-grid`);

    html2canvas(element).then(canvas => {
        var glass, w, h, bw;
        // Create an image element
        let img = document.getElementById('magnify-image');
        // Set the image source to the base64 encoded string
        img.src = canvas.toDataURL('image/png');

        // Append the image to the document
        document.querySelector(".grid-column2").appendChild(img);

        /* Create magnifier glass: */
        glass = document.createElement("DIV");
        glass.setAttribute("class", "img-magnifier-glass");
        glass.style.visibility = "hidden";

        /* Set background properties for the magnifier glass: */
        glass.style.backgroundImage = "url('" + img.src + "')";
        glass.style.backgroundRepeat = "no-repeat";
        glass.style.backgroundSize = (img.width * zoom) + "px " + (img.height * zoom) + "px";
        bw = 3;
        w = glass.offsetWidth + 100;
        h = glass.offsetHeight + 100;

        document.querySelector(".grid-column2").appendChild(glass);

        /* Execute a function when someone moves the magnifier glass over the image: */
        glass.addEventListener("mousemove", moveMagnifier);
        img.addEventListener("mousemove", moveMagnifier);

        /*and also for touch screens:*/
        glass.addEventListener("touchmove", moveMagnifier);
        img.addEventListener("touchmove", moveMagnifier);

        showHideGlass();

        function moveMagnifier(e) {
            var pos, x, y;
            /* Prevent any other actions that may occur when moving over the image */
            e.preventDefault();
            /* Get the cursor's x and y positions: */
            pos = getCursorPos(e);
            x = pos.x;
            y = pos.y;
            /* Prevent the magnifier glass from being positioned outside the image: */
            if (x > img.width - (w / zoom)) {
                x = img.width - (w / zoom);
            }
            if (x < w / zoom) {
                x = w / zoom;
            }
            if (y > img.height - (h / zoom)) {
                y = img.height - (h / zoom);
            }
            if (y < h / zoom) {
                y = h / zoom;
            }
            /* Set the position of the magnifier glass: */
            glass.style.left = (x - w) + "px";
            glass.style.top = (y - h) + "px";
            /* Display what the magnifier glass "sees": */
            glass.style.backgroundPosition = "-" + ((x * zoom) - w + bw) + "px -" + ((y * zoom) - h + bw) + "px";
        }

        function getCursorPos(e) {
            var a, x = 0, y = 0;
            e = e || window.event;
            /* Get the x and y positions of the image: */
            a = img.getBoundingClientRect();
            /* Calculate the cursor's x and y coordinates, relative to the image: */
            x = e.pageX - a.left;
            y = e.pageY - a.top;
            /* Consider any page scrolling: */
            x = x - window.pageXOffset;
            y = y - window.pageYOffset;
            return {x: x, y: y};
        }
    });
}

function showHideGlass() {
    const button = document.querySelector("#magnify-img");
    const glass = document.querySelector(".img-magnifier-glass");

    button.addEventListener("click", function () {
        if (glass.style.visibility === "visible") {
            glass.style.visibility = "hidden";
        } else {
            glass.style.visibility = "visible";
        }
    });
}

export {
    BASE_WIDTH_RANGE_SELECTOR,
    BASE_LENGTH_RANGE_SELECTOR,
    HEADBOARD_WIDTH_RANGE_SELECTOR,
    enableDisableFabricsMenus,
    extraActions,
    buildRadioButtons,
    hideBackgroundButton,
    addListenersOnButtons,
    setListenersOnRebuild,
    scrollMenuToTop,
    showHideChat,
    showHidePillows,
    syncLeykaEidhCheckboxes,
    submitAiForm,
    addTextToChat,
    removeTypingFromChat,
    addTypingToChat,
    requestGPT4,
    checkUrlForLeykaEidh,
    changeSrcOfImageZoom,
    magnify,
    showHideGlass
};

import $ from "jquery";
import {getJson} from "./getJson";
import replaceImages from "./imagesTools";
import {
    buildRadioButtons,
    calcPrice, changeSrcOfImageZoom,
    disableBedFeet,
    extraActions,
    handleCategoryA,
    listenerForCheckboxes,
    setListenersOnRebuild,
    syncLeykaEidhCheckboxes,
} from "./UIfunctions";
import {
    getActiveStateProps,
    setMinMaxValueAccordingToBase,
    setMinMaxValueAccordingToHeadboard
} from "./dimensionsFunctions";
import {bedAttributes, BEDS, LEYKA} from "./consts";
import {activeParentCodes} from "./index";
import {getNextLetter} from "./getNextLetter";
import {getPreviousLetter} from "./getPreviousLetter";
import {findSelections} from "./pureSelect";

const LETTERS = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n"];

let dataActive = "";

export class MenuItem {
    constructor(id, active, name, category, structure, thumbUrl, images, subCategoryItems, showInFrontend, dontShowInFrontend, realCategory) {
        this.id = id;
        this.active = active;
        this.name = name;
        this.category = category;
        this.structure = structure;
        this.thumbUrl = thumbUrl;
        this.images = images;
        this.subCategoryItems = subCategoryItems;
        this.showInFrontend = showInFrontend;
        this.dontShowInFrontend = dontShowInFrontend;
        this.realCategory = realCategory;
    }

    buildMenuItem(data) {
        if (this.showInFrontend?.overwriteCategory) {
            $(`#${this.showInFrontend.overwriteCategory}`).append(this.buildHtml(data));
        } else $(`#category-${this.category}`).append(this.buildHtml(data));
        this.reconstructMenuListener();
        if (this.showInFrontend?.overwriteCategory && this.subCategoryItems) {
            for (const item of this.subCategoryItems) {
                let menuItem = new MenuItem(
                    item.id,
                    item.active,
                    item.showInFrontend?.name ? item.showInFrontend.name : item.name,
                    item.category,
                    item.structure,
                    item.thumbUrl,
                    item.images,
                    item.subCategoryItems,
                    item.showInFrontend,
                    item.dontShowInFrontend,
                    item.category,
                );
                menuItem.buildMenuItem();
            }
        } else {
            if (
                document.querySelector("#category-a .menu-item.active")?.dataset?.name === LEYKA &&
                this.subCategoryItems &&
                this.active &&
                this.category === undefined
            ) {
                const {parentCode} = this.showInFrontend;
                const last = activeParentCodes.lastUsed();
                const lastUsedElement =
                    document.querySelector("#category-a .menu-item[data-name='" + last + "']") ?? document.querySelector("#category-a .menu-item.active");
                const lastUsedElementCode = activeParentCodes.activeParent(lastUsedElement);
                if (parentCode !== lastUsedElementCode) return;
                for (const item of this.subCategoryItems) {
                    let menuItem = new MenuItem(
                        item.id,
                        item.active,
                        item.showInFrontend?.name ? item.showInFrontend.name : item.name,
                        getPreviousLetter(item.category),
                        item.structure,
                        item.thumbUrl,
                        item.images,
                        item.subCategoryItems,
                        item.showInFrontend,
                        item.dontShowInFrontend,
                    );
                    menuItem.buildMenuItem();
                }
            } else if (document.querySelector("#category-a .menu-item.active")?.dataset?.name === LEYKA && this.subCategoryItems) {
                for (const item of this.subCategoryItems) {
                    let menuItem = new MenuItem(
                        item.id,
                        item.active,
                        item.showInFrontend?.name ? item.showInFrontend.name : item.name,
                        getPreviousLetter(item.category),
                        item.structure,
                        item.thumbUrl,
                        item.images,
                        item.subCategoryItems,
                        item.showInFrontend,
                        item.dontShowInFrontend,
                        item.category,
                    );

                    menuItem.buildMenuItem();
                }
            } else if (this.active && this.subCategoryItems) {
                for (const item of this.subCategoryItems) {
                    let menuItem = new MenuItem(
                        item.id,
                        item.active,
                        item.showInFrontend?.name ? item.showInFrontend.name : item.name,
                        item.category,
                        item.structure,
                        item.thumbUrl,
                        item.images,
                        item.subCategoryItems,
                        item.showInFrontend,
                        item.dontShowInFrontend,
                        item.category,
                    );
                    menuItem.buildMenuItem();
                }
            }
        }
    }

    buildHtml() {
        let a = "";
        let categoryDTitle = "";
        if (this.showInFrontend) {
            for (let [key, value] of Object.entries(this.showInFrontend)) {
                a += `data-prop-${key}="${value}" `;
                if (key === "code") {
                    categoryDTitle = value;
                } else if (key === "name") {
                    categoryDTitle = value;
                }
            }
        }
        let title = "";
        if (this.category === "b") {
            for (let [key, value] of Object.entries(bedAttributes)) {
                if (key === this.name.replace(" ", "")) {
                    title = `title="${value}"`;
                }
            }
        }
        return `
            <div 
                class='menu-item ${this.active ? "active " : ""}${this.structure ? this.structure : ""}' 
                id='menu-item-${this.category}-${this.id}' 
                data-name = '${this.name}'
                data-category='${this.category}'  
                data-id='${this.id}'
                ${this.category === "d" ? `title="${categoryDTitle}"` : ""}
                data-active = ${!!this.active}
                data-real-category = ${this.realCategory}
              
                ${this.showInFrontend?.styles ? `style ="${this.showInFrontend.styles.map((el) => el)}"` : ""}
                ${a}
                ${title}
                data-foot-disabled = ${!!this.dontShowInFrontend?.bedFootDisabled}
            >
        ${this.thumbUrl ? `<img src='${this.thumbUrl}' >` : ""}            
                 <span class="menu-item-name">${this.showInFrontend?.subcategoryName ? this.showInFrontend.subcategoryName : this.name} </span>
                 ${
            this.showInFrontend.constName
                ? `
                 <label class="switch-leyka">
                        <input type="checkbox" class="leyka-menu-checkboxes"  data-parent-constName="${this.showInFrontend.constName}" >
                        <div class="slider-leyka"></div>
                    </label>`
                : ""
        }
                 
          
            </div>
        `;
    }

    reconstructMenuListener() {
        if ($("#category-a .menu-item.active")[0]?.dataset.name === LEYKA && this.active && this.realCategory === "e") {
            buildRadioButtons(this);
        }

        if (this.showInFrontend?.overwriteCategory) {
            let category = this.category;
            document.querySelector(`#menu-item-${category}-${this.id}`).addEventListener("click", async function (e) {
                if (this.classList.contains("active")) return;
                if (e.isTrusted) {
                    $(".layout-selection").removeClass("active");
                }
                if (!this.classList.contains("active")) {
                    // Add the active class to the clicked item and remove it from the others.
                    $(this).siblings().removeClass("active");
                    $(this).addClass("active");
                    // Get the json and build the menu.
                    await getJson(e, this.dataset.category, this.dataset.id).then((data) => {

                        let menuData = data.menuData;

                        let {layoutState} = data;
                        global.layoutState = layoutState;
                        global.isBedsOn = data.isBedsOn;
                        replaceImages(Object.values(layoutState));
                        setListenersOnRebuild();

                        // Empty the sub-item menus.
                        if (this.dataset.propOverwritecategory === "background-subcategories") {
                            let floors = document.querySelector("#background-floors").childNodes;
                            Array.from(floors).forEach((el) => {
                                el.remove();
                            });
                        } else if (
                            this.dataset.propOverwritecategory !== "bed-feet" &&
                            this.dataset.propOverwritecategory !== "bed-foot" &&
                            this.dataset.propOverwritecategory !== "background-floors"
                        ) {
                            for (let letter of LETTERS.slice(LETTERS.indexOf(category) + 1)) {
                                $(`#category-${letter}`).empty();
                            }
                        }
                        if (menuData[0].subCategoryItems) {
                            menuData[0].subCategoryItems.forEach((el) => {
                                let menuItem = new MenuItem(
                                    el.id,
                                    el.active,
                                    el.showInFrontend?.name ? el.showInFrontend.name : el.name,
                                    el.category,
                                    el.structure,
                                    el.thumbUrl,
                                    el.images,
                                    el.subCategoryItems,
                                    el.showInFrontend,
                                    el.dontShowInFrontend,
                                    el.category,
                                );
                                menuItem.buildMenuItem();
                            });
                        }
                        // replaceImages(imagesToReplaceArray);
                        // imagesToReplaceArray = [];
                    });
                }
                await calcPrice({
                    connectRange: document.querySelector("#connect-range"),
                });
                // changeSrcOfImageZoom();
            });
        } else {
            let category = this.category;
            if (category === undefined) {
                return;
            }
            document.querySelector(`#menu-item-${category}-${this.id}`)?.addEventListener("click", async function (e) {
                // If the switcher was clicked do nothing.
                if (e.target.classList.contains("slider-leyka") || e.target.classList.contains("leyka-menu-checkboxes")) {
                    return;
                }
                if (this.classList.contains("active")) return;
                if (e.isTrusted) {
                    $(".layout-selection").removeClass("active");
                }

                if (!this.classList.contains("active")) {
                    extraActions($(this));
                    // Add the active class to the clicked item and remove it from the others.
                    $(this).siblings().removeClass("active");
                    $(this).addClass("active");
                    // Get the json and build the menu.
                    if (category !== "a" && $("#category-a .menu-item.active")[0].dataset.name === LEYKA) {
                        await getJson(e, getNextLetter(category), this.dataset.id).then((data) => {
                            if ($("#category-a .menu-item.active")[0].dataset.name === LEYKA && this.dataset.realCategory === "e") {
                                buildRadioButtons(data.menuData[0]);
                            }

                            dataActive = data;
                            let {menuData} = data;
                            global.menuStateNames = data.menuStateWithPropNames;
                            let {layoutState} = data;
                            global.layoutState = layoutState;
                            global.isBedsOn = data.isBedsOn;
                            replaceImages(Object.values(layoutState));

                            // Empty the sub-item menus.
                            for (let letter of LETTERS.slice(LETTERS.indexOf(category) + 1)) {
                                $(`#category-${letter}`).empty();
                            }

                            if (menuData[0].subCategoryItems) {
                                menuData[0].subCategoryItems.forEach((el) => {
                                    let menuItem = new MenuItem(
                                        el.id,
                                        el.active,
                                        el.showInFrontend?.name ? el.showInFrontend.name : el.name,
                                        getPreviousLetter(el.category),
                                        el.structure,
                                        el.thumbUrl,
                                        el.images,
                                        el.subCategoryItems,
                                        el.showInFrontend,
                                        el.dontShowInFrontend,
                                        el.category,
                                    );

                                    menuItem.buildMenuItem();
                                });
                            }
                        });
                    } else {
                        activeParentCodes.setLastUsed(document.querySelector("#category-a .menu-item.active"));

                        await getJson(e, this.dataset.category, this.dataset.id).then((data) => {
                            let {menuData} = data;
                            dataActive = data;
                            global.menuStateNames = data.menuStateWithPropNames;

                            let {layoutState} = data;
                            global.layoutState = layoutState;
                            global.isBedsOn = data.isBedsOn;
                            replaceImages(Object.values(layoutState));
                            // Empty the sub-item menus.
                            for (let letter of LETTERS.slice(LETTERS.indexOf(category) + 1)) {
                                $(`#category-${letter}`).empty();
                            }
                            if (menuData[0].subCategoryItems) {
                                menuData[0].subCategoryItems.forEach((el) => {
                                    let menuItem = new MenuItem(
                                        el.id,
                                        el.active,
                                        el.showInFrontend?.name ? el.showInFrontend.name : el.name,
                                        el.category,
                                        el.structure,
                                        el.thumbUrl,
                                        el.images,
                                        el.subCategoryItems,
                                        el.showInFrontend,
                                        el.dontShowInFrontend,
                                        el.category,
                                    );
                                    menuItem.buildMenuItem(data);
                                });
                            }
                        });
                    }

                    if (category === "b" || category === "a") {
                        handleCategoryA(setMinMaxValueAccordingToBase, setMinMaxValueAccordingToHeadboard);
                        const isFootDisabled = document.querySelector("#category-b .menu-item.active")?.dataset?.footDisabled;

                        if (isFootDisabled === "true" && !$(`#category-a .menu-item[data-name="${LEYKA}"]`).hasClass("active")) {
                            $("#bed-foot").addClass("disabled");
                        } else {
                            $("#bed-foot").removeClass("disabled");
                        }
                    }
                    if (category === "a") {
                        document.querySelector("#bed-text").textContent = $("#category-b .menu-item.active").attr("data-prop-bed-text");
                    }
                    if ($(`#category-a .menu-item[data-name='${BEDS}']`).hasClass("active") && activeParentCodes.lastUsed() === BEDS) {
                        activeParentCodes.setActiveBed({
                            bedElement: $("#category-b .menu-item.active")[0],
                        });
                    }
                    await calcPrice(document.querySelector("#connect-range"));
                    // adds a leyka-active class to the category-b if the category-a is leyka
                    // so we can style the category-b menu items differently
                    document.querySelector("#leyka-checkbox").checked = dataActive.lefkaEidhCheckboxesState.leyka_eidh_general_price_checkbox;
                    document.querySelector("#krevati-checkbox").checked = dataActive.lefkaEidhCheckboxesState.bed_general_price_checkbox;
                    if (this.dataset.category === "a" && this.dataset.name === "Λευκά Είδη") {
                        if (!document.querySelector('#leyka-checkbox').checked) {
                            document.querySelector('#leyka-checkbox').click();
                        }
                        document.querySelector("#category-b").classList.add("leyka-active");
                        document.querySelector("#category-c").classList.add("leyka-active");
                        document.querySelector("#bed-attribute").style = "display: none";
                        //document.querySelector(".leyka-checkbox-container").style = "display: flex";
                    } else if (this.dataset.category === "a" && this.dataset.name !== "Λευκά Είδη") {
                        document.querySelector("#category-b").classList.remove("leyka-active");
                        document.querySelector("#category-c").classList.remove("leyka-active");
                        document.querySelector("#bed-attribute").style = "display: initial";
                        //document.querySelector(".leyka-checkbox-container").style = "display: none";
                    }
                    if (
                        (this.dataset.category === "a" || this.dataset.category === "b") &&
                        document.querySelector("#category-a .menu-item.active")?.dataset?.name !== LEYKA
                    ) {
                        findSelections();
                    }

                    if (document.querySelector("[data-prop-constName='diakosmitika_maksilaria']")?.classList.contains("active")) {
                        document.querySelector("#pillow-dimantions").style.display = "flex";
                    } else {
                        document.querySelector("#pillow-dimantions").style.display = "none";
                    }

                    if (document.querySelector("[data-name='Λευκά Είδη']")?.classList.contains("active")) {
                        document.querySelector("#leyka-dimantions").style.display = "flex";
                            $('.vscomp-clear-button').trigger('click');
                    } else {
                        document.querySelector("#leyka-dimantions").style.display = "none";
                    }
                    if (document.querySelector("#category-b .menu-item.active")?.dataset?.name === "Σετ Σεντόνια") {
                        document.querySelector("[data-div-name='katosentono']").style.display = "none";
                        document.querySelector("[data-div-name='panosentono']").style.display = "none";
                        document.querySelector("[data-div-name='set_sentonia_katosentono']").style.display = "initial";
                        document.querySelector("[data-div-name='set_sentonia_panosentono']").style.display = "initial";
                        document.querySelector(".fake-sub-menu-diakosmitika").style.display = "initial";
                    } else if (
                        document.querySelector("#category-b .menu-item.active")?.dataset?.name === "Κατωσέντονο" ||
                        document.querySelector("#category-b .menu-item.active")?.dataset?.name === "Πανωσέντονο"
                    ) {
                        document.querySelector("[data-div-name='katosentono']").style.display = "initial";
                        document.querySelector("[data-div-name='panosentono']").style.display = "initial";
                        document.querySelector("[data-div-name='set_sentonia_katosentono']").style.display = "none";
                        document.querySelector("[data-div-name='set_sentonia_panosentono']").style.display = "none";
                    }

                    if (
                        document.querySelector("[data-name='Διακοσμητικά Μαξιλάρια']")?.classList.contains("active") ||
                        document.querySelector("[data-name='Σετ Σεντόνια']")?.classList.contains("active")
                    ) {
                        document.querySelector("#leyka-dimantions").style.display = "none";
                        document.querySelector("#show-hide").style.display = "none";
                    }

                    // hide fake-sub-menu-diakosmitika if we are not in set sentonia
                    if (document.querySelector("#category-b .menu-item.active")?.dataset?.name !== "Σετ Σεντόνια") {
                        document.querySelector(".fake-sub-menu-diakosmitika").style.display = "none";
                    }

                    // Sync the checkboxes (check/uncheck them)
                    syncLeykaEidhCheckboxes(dataActive.lefkaEidhCheckboxesState);

                    // Sync the radio buttons (check/uncheck them) according to `lefkaEidhDimensionsState`.
                    const activeLeyko = document.querySelector(".menu-categories.leyka-active .menu-item.active");
                    const activeItemConstName = activeLeyko?.dataset.propConstname;
                    if (activeItemConstName) {
                        const itemActiveCode = dataActive.lefkaEidhDimensionsState[activeItemConstName]?.code;
                        if (itemActiveCode && document.getElementById(`lefka-eidh-radio-${itemActiveCode}`)) {
                            document.getElementById(`lefka-eidh-radio-${itemActiveCode}`).checked = true;
                        } else {
                            const radioButtons = document.querySelectorAll(".dimensions-radio-button");
                            const lastDimension = dataActive.lefkaEidhDimensionsState[activeItemConstName]?.dimensions;
                            radioButtons.forEach((radioButton) => {
                                radioButton.value === lastDimension ? radioButton.click() : null;
                            });
                        }
                    }

                    // Add listeners on the checkboxes
                    const boxes = document.querySelectorAll(".leyka-menu-checkboxes");
                    boxes.forEach((box) => {
                        listenerForCheckboxes(box);
                    });
                    //changeSrcOfImageZoom();
                    setListenersOnRebuild(category);
                    getActiveStateProps();
                    disableBedFeet();
                }
            });
        }
    }
}
